var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "800" } },
    [
      _c(
        "v-card",
        { staticClass: "py-0 fill" },
        [
          _c("v-card-title", { staticClass: "font-weight-medium" }, [
            _vm._v("Note"),
          ]),
          _c("v-card-subtitle", [
            _vm._v(
              " " +
                _vm._s(_vm.item.page_name) +
                " " +
                _vm._s(_vm.item.position_name) +
                " " +
                _vm._s(_vm.item.feature_text) +
                " "
            ),
          ]),
          _c(
            "v-card-text",
            [
              _c("v-textarea", {
                attrs: {
                  "background-color": "input",
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  rows: "4",
                  "no-resize": "",
                },
                model: {
                  value: _vm.modifiedNote,
                  callback: function ($$v) {
                    _vm.modifiedNote = $$v
                  },
                  expression: "modifiedNote",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "text-capitalize",
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("update:value", false)
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "text-capitalize primary mr-2",
                  on: { click: _vm.saveNote },
                },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }